<template>
  <div class="mt-3 p-2">
    <b-container fluid class="mb-2">
      <b-row class="mb-2">
        <b-col>
          <h5 class="text-uppercase heading">Announcement</h5>
        </b-col>
        <b-col class="text-right">
         
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col md="2">
          <!-- Select User Per Page  -->
          <b-form-select v-model="item_per_page" @change="changeItemNumber">
            <b-form-select-option value="10">10</b-form-select-option>
            <b-form-select-option value="50">50</b-form-select-option>
            <b-form-select-option value="100">100</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col md="10">
          <!-- Filter -->
          <b-form-group label="" label-for="filter-input" class="mb-0">
            <b-input-group>
              <b-form-input
                id="filter-input"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              ></b-form-input>

              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''"
                  >Clear</b-button
                >
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-tabs content-class="mt-3">
        <b-tab title="Announcements" active>
          <div class="mb-3 d-flex justify-content-end">
            <b-button variant="success" @click="downloadAnnouncementsCSV">
              <i class="fa fa-download" aria-hidden="true"></i>
            </b-button>
          </div>

          <b-table
            class="dataTable rounded-left"
            :outlined="true"
            :items="items"
            :fields="fields"
            :filter="filter"
            @filtered="filtered"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            head-variant="none"
            table-variant="none"
            :busy="isBusy"
          >
            <template #cell(#)="data">
              <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
              <span v-else>{{
                (currentPage - 1) * item_per_page + (data.index + 1)
              }}</span>
            </template>
            <template #cell(image)="row">
              <img
                :src="row.item.image"
                class="rounded"
                width="50px"
                height="50px"
              />
            </template>
            <template #cell(assignment_level)="row">
              <span v-if="row.item.assignment_level === 'project_level'"
                >Project Level</span
              >
              <span v-else-if="row.item.assignment_level === 'zone_level'"
                >Zone Level</span
              >
              <span v-else-if="row.item.assignment_level === 'plot_level'"
                >Plot Level</span
              >
              <span v-else-if="row.item.assignment_level === 'general'"
                >General</span
              >
            </template>
            <template #cell(url)="row">
              <a :href="'/announcement/' + row.item._id" target="__blank">{{
                row.item.title
              }}</a>
            </template>
            <template #cell(likes)="data">
              <i class="fas fa-heart text-danger"></i>

              <span v-if="data.item.likes">
                {{ data.item.likes.length }}
              </span>
              <span v-else>
                0
              </span>
            </template>
            <template #cell(comments)="data">
              <i class="fas fa-comment text-success"></i>
              <span v-if="data.item.comments">
                {{ data.item.comments.length }}
              </span>
              <span v-else>
                0
              </span>
            </template>

            <template #cell(actions)="row">
              <b-dropdown
                size="sm"
                id="dropdown-1"
                text="Actions"
                variant="primary"
              >
                <b-dropdown-item>
                  <router-link
                    :to="'/announcement/' + row.item._id"
                    style="text-decoration: none; color: #000"
                  >
                    View
                  </router-link>
                </b-dropdown-item>
           
              </b-dropdown>
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Comments Overview">
          <!-- <div class="mb-3 d-flex justify-content-end">
            <b-button variant="success" @click="downloadCommentsCSV">
              <i class="fa fa-download" aria-hidden="true"></i>
            </b-button>
          </div> -->

          <b-table
            class="dataTable rounded-left"
            :outlined="true"
            :items="commentsItems"
            :fields="commentsFields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            head-variant="none"
            table-variant="none"
            :busy="isBusyComments"
          >
            <template #cell(#)="data">
              <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
              <span v-else>{{
                (currentPage - 1) * item_per_page + (data.index + 1)
              }}</span>
            </template>

            <template #cell(created_ts)="data">
              {{ data.item.created_ts | moment('DD-MM-YYYY hh:mm:ss A') }}
            </template>
            <template #cell(comments)="data">
              <i class="fas fa-comment text-success"></i>
              {{ data.item.comments }}
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-tab>
        <b-tab title="Likes Overview">
          <!-- <div class="mb-3 d-flex justify-content-end">
            <b-button variant="success" @click="downloadLikesCSV">
              <i class="fa fa-download" aria-hidden="true"></i>
            </b-button>
          </div> -->

          <b-table
            class="dataTable rounded-left"
            :outlined="true"
            :items="likesItems"
            :fields="likesFields"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-desc.sync="sortDesc"
            head-variant="none"
            table-variant="none"
            :busy="isBusyLikes"
          >
            <template #cell(#)="data">
              <span v-if="currentPage == 1">{{ data.index + 1 }}</span>
              <span v-else>{{
                (currentPage - 1) * item_per_page + (data.index + 1)
              }}</span>
            </template>

            <template #cell(created_ts)="data">
              {{ data.item.created_ts | moment('DD-MM-YYYY hh:mm:ss A') }}
            </template>

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
          </b-table>
        </b-tab>
      </b-tabs>
      <div class="d-flex justify-content-center mt-4">
        <b-pagination
          pills
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
        ></b-pagination>
      </div>
    </b-container>
  </div>
</template>

<script>


export default {
  name: 'Users',
  components: {
  },
  data() {
    return {
      item_per_page: 10,
      perPage: 10,
      currentPage: 1,
      sortDesc: false,
      filter: null,
      isBusy: false,
      isBusyComments: false,
      isBusyLikes: false,
      items: [],
      downloadItems: [],
      commentsItems: [],
      likesItems: [],
      fields: [
        '#',

        {
          key: 'image',
          label: 'Cover Image',
        },
        {
          key: 'url',
          label: 'Title',
          sortable: true,
        },
        {
          key: 'likes',
          label: 'Likes',
          sortable: true,
        },
        {
          key: 'comments',
          label: 'Comments',
          sortable: true,
        },
        {
          key: 'assignment_level',
          label: 'Assignment Level',
          sortable: true,
        },
        'actions',
      ],
      commentsFields: [
        '#',
        {
          key: '_announcement_id.title',
          label: 'Announcement',
        },
        {
          key: '_user_id.name',
          label: 'User Name',
        },
        {
          key: 'comment',
          label: 'Comment',
        },
        {
          key: 'created_ts',
          label: 'Time',
          sortable: true,
        },
        {
          key: 'admin_comment.comment',
          label: 'Admin Reply',
          formatter: (value) => {
            if (value) return 1;
            else return 0;
          },
          sortable: true,
        },
      ],
      likesFields: [
        '#',
        {
          key: '_announcement_id.title',
          label: 'Announcement',
        },
        {
          key: '_user_id.name',
          label: 'User Name',
        },

        {
          key: 'created_ts',
          label: 'Time',
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    this.fetchData();
    this.fetchComments();
    this.fetchLikes();
  },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  methods: {
    filtered(filteredItems) {
      this.downloadItems = filteredItems;
    },

    changeItemNumber() {
      this.perPage = this.item_per_page;
    },

    fetchLikes() {
      this.isBusyLikes = true;
      window.axios.get('likes').then((resp) => {
        if (resp.data.success) {
          this.likesItems = resp.data.likes;
          this.isBusyLikes = false;
        }
      });
    },

    fetchComments() {
      this.isBusyComments = true;
      window.axios.get('comments').then((resp) => {
        if (resp.data.success) {
          this.commentsItems = resp.data.data;
          this.isBusyComments = false;
        }
      });
    },

    fetchData() {
      this.isBusy = true;

      window.axios.get('social-media').then((resp) => {
        console.log(resp);
        if (resp.data.success) {
          this.items = resp.data.data;
          this.downloadItems = this.items;
          this.isBusy = false;
        }
        setTimeout(() => (this.success = false), 3000);
      });
    },
    deleteAnnouncement(id) {
      let prompt = confirm('Are you sure want to delete ? ');
      if (prompt) {
        window.axios.delete('social-media/' + id).then(() => {
          this.fetchData();
        });
      }
    },

    downloadAnnouncementsCSV() {
      let csv = '\ufeff' + 'TITLE,LIKES,COMMENTS,ASSIGNMENT LEVEL\n';
      this.downloadItems.forEach((el) => {
        let likes = el['likes'].length;
        let comments = el['comments'].length;
        var line =
          el['title'] +
          ',' +
          likes +
          ',' +
          comments +
          ',' +
          el['assignment_level'] +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Announcements.csv';
      link.click();
    },

    downloadCommentsCSV() {
      let csv =
        '\ufeff' + 'ANNOUNCEMENT,USER NAME, COMMENT, TIME, ADMIN REPLY\n';
      this.items.forEach((el) => {
        let adminReply = el['admin_comment'] ? 1 : 0;
        let announcement = el['_announcement_id']
          ? el['_announcement_id'].title
          : '';
        let userName = el['_user_id'] ? el['_user_id'].name : '';
        var line =
          announcement +
          ',' +
          userName +
          ',' +
          el['comment'] +
          ',' +
          el['created_ts'] +
          ',' +
          adminReply +
          '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Comments.csv';
      link.click();
    },

    downloadLikesCSV() {
      let csv = '\ufeff' + 'NAME,CODE\n';
      this.items.forEach((el) => {
        var line = el['name'] + ',' + el['code'] + '\n';
        csv += line;
      });
      var blob = new Blob([csv], { type: 'csv/plain' });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'Farms.csv';
      link.click();
    },
  },
};
</script>

<style scoped>
.heading {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 3.5%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #454545;
}

.view-icon {
  color: #1da040;
  height: 20px;
  width: 30px;
}

/deep/ .dataTable thead {
  background-color: #dcffd9;
  height: 64px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 3.5%;
  font-family: 'Poppins', sans-serif;
  padding-top: 14px;
  padding-bottom: 14px;
  color: #303030;
}

/deep/ .dataTable {
  text-align: center;
  margin-bottom: 21.5px;
  margin-left: 2px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

/deep/ .dataTable tr td {
  font-family: 'Poppins', sans-serif;
  font-size: 17px;
  letter-spacing: 3.5%;
  line-height: 25.5px;
  font-weight: 400;
  padding-top: 12px;
  padding-bottom: 12px;
}
</style>
